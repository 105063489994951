.wrapper {
    margin-top: 5rem;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    height: 100vh;

    .title {
        // font-size: 3rem;
    }

    .btnColor {
        color: var(--white) !important;
        background: var(--text-secondary);
        border-radius: 20px;
        border: 1px solid var(--btn-border-color);
        &:hover {
            background: var(--btn-hover-bg);
        }
    }
}

.rippleBackground {
    z-index: -10 !important;

    .circle {
        position: absolute;
        border-radius: 50%;
        background: var(--text-secondary);
        animation: ripple 15s infinite;
        box-shadow: 0px 0px 1px 0px #2d2e2e;
    }

    .large {
        width: 50%;
        height: 100%;
        left: 25%;
        top: -50%;
        transform: translateX(-50%);
    }

    .xlarge {
        width: 60%;
        height: 120%;
        left: 20%;
        top: -60%;
        transform: translateX(-50%);
    }

    .xxlarge {
        width: 70%;
        height: 140%;
        left: 15%;
        top: -70%;
        transform: translateX(-50%);
    }

    .shade1 {
        opacity: 0.05;
    }

    .shade2 {
        opacity: 0.08;
    }

    .shade3 {
        opacity: 0.1;
    }

    @keyframes ripple {
        0% {
            transform: scale(0.8);
        }

        50% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(0.8);
        }
    }
}

.siteImage {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    img {
        width: 60%;
        border-radius: 6px;
    }
}

@media (max-width: 575.98px) {
    .wrapper {
        height: 20vh;
    }

    .siteImage {
        overflow: hidden;

        img {
            width: 100%;
        }
    }
}

//tab
@media (max-width: 991.98px) {
    .rippleBackground {
        .large {
            width: 40%;
            height: 30%;
            left: 30%;
            top: -10%;
            transform: translateX(-50%);
        }

        .xlarge {
            width: 50%;
            height: 40%;
            left: 25%;
            top: -17%;
            transform: translateX(-50%);
        }

        .xxlarge {
            width: 60%;
            height: 50%;
            left: 20%;
            top: -20%;
            transform: translateX(-50%);
        }
    }

    .siteImage {
        overflow: hidden;

        img {
            width: 100%;
        }
    }
}
