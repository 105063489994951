.cookieConsentBanner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f1f3f5;
  color: #212529;
  padding: 10px;
  display: flex;
  flex-direction: row; /* Default layout for larger screens */
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;

  .text {
    flex: 1;
    font-size: 0.85rem;
    color: #212529;
    margin-right: 20px;

    @media (max-width: 768px) {
      margin-right: 0;
      text-align: center;
    }
  }

  .link {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
  }

  .buttonContainer {
    display: flex;
    gap: 10px;

    @media (max-width: 768px) {
      flex-direction: column; /* Stack buttons vertically */
      gap: 5px;
      margin-top: 10px;
    }
  }

  .acceptButton,
  .declineButton {
    padding: 10px 16px;
    font-size: 0.85rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;

    @media (max-width: 480px) {
      font-size: 0.75rem;
      padding: 8px 12px;
      min-width: 100px;
    }
  }

  .acceptButton {
    background-color: #ffffff;
    color: #212529;
    border: 1px solid #ced4da;
    transition: background-color 0.2s;

    &:hover {
      background-color: #e2e6ea;
    }
  }

  .declineButton {
    background-color: transparent;
    color: #007bff;
    border: none;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column; /* Stack banner elements vertically */
    align-items: center;
    padding: 15px;

    .text {
      margin-bottom: 10px;
    }
  }
}
