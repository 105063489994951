.progressContainer {
    width: 100%;
    height: 3px;
    backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.479);

    .progressBar {
        height: 3px;
        background: var(--text-secondary);
        width: 0%;
    }
}

.navText {
    a {
        color: var(--text-primary) !important;
    }
}

.cusbtn {
    background: var(--white) !important;
    color: var(--text-secondary) !important;
    border: none !important;
    font-weight: 600 !important;
    border: none !important;
    border-radius: 20px !important;
    &:hover {
        background: var(--btn-active-bg) !important;
        color: var(--white) !important;
    }
}


.homeWrapper {
    height: 155vh;
}

.challengesWrapper {
    background-color: #fcfbfb;
}

.audienceWrapper {
    background-color: #b0c7f51a !important;
    width: 100%;
    height: 100%;
}

.missionWrapper {
    display: flex;
    justify-content: center;
    height: 50vh;
    padding-top: 5rem;
    position: relative;
    overflow: hidden !important;

    .missionSubWrapper {
        width: 70%;
        height: 70%;
        border: 2px solid #8976fd;
        border-radius: 40px;

        .text {
            font-weight: 500;
            // font-size: 2rem;
        }
    }

    .quotationOne {
        top: 14%;
        left: 14%;
    }

    .greenDots {
        top: 71%;
        left: 22%;
    }

    .fillDots {
        top: 15%;
        left: 80%;
        overflow: hidden !important;
    }

    .quotationTwo {
        top: 65%;
        left: 77%;
        overflow: hidden !important;
        svg {
            rotate: 180deg;
        }
    }
}

.featureWrapper {
    background-color: #b0c7f51a !important;
    width: 100%;
    height: 60vh;
}

.footerWrapper {
    background-color: #333;
}

// desktop
@media (min-width: 1400px) {
    .homeWrapper {
        height: 110vh;
    }

    .missionWrapper {
        .missionSubWrapper {
            width: 70%;
            height: 70%;
            padding-top: 1rem;
            .text {
                font-size: 1.3rem;
                width: 85%;
            }
        }

        .quotationOne {
            top: 10%;
            left: 14%;
        }

        .greenDots {
            top: 71%;
            left: 22%;
        }

        .fillDots {
            top: 12%;
            left: 82%;
        }

        .quotationTwo {
            top: 68%;
            left: 79%;
        }
    }

    .featureWrapper {
        height: 40vh;
    }
}

// desktop
// @media (min-width: 1400px) {
//     .homeWrapper{
//         height: 110vh;
//     }
// }

//tab
@media (max-width: 991.98px) {
    .homeWrapper {
        height: 85vh;
    }

    .missionWrapper {
        .missionSubWrapper {
            width: 80%;
            height: 70%;
            padding-top: 1rem;
            .text {
                font-size: 1.2rem;
                width: 95%;
            }
        }

        .quotationOne {
            top: 10%;
            left: 9%;
        }

        .greenDots {
            top: 71%;
            left: 22%;
        }

        .fillDots {
            top: 10%;
            left: 80%;
        }

        .quotationTwo {
            top: 68%;
            left: 75%;
        }
    }

    .featureWrapper {
        height: 50vh;
    }
}

// mobile
@media (max-width: 575.98px) {
    .homeWrapper {
        height: 80vh;
    }

    .missionWrapper {
        margin-bottom: 4rem;
        .missionSubWrapper {
            width: 92%;
            height: 90%;
            padding-top: 3rem;
            .text {
                padding-top: 0.5rem;
                font-size: 1.2rem;
            }
        }

        .quotationOne {
            top: 10%;
            left: 4%;
        }

        .greenDots {
            top: 87%;
            left: 17%;
        }

        .fillDots {
            top: 10%;
            left: 80%;
        }

        .quotationTwo {
            top: 83%;
            left: 66%;
        }
    }
    .featureWrapper {
        height: 105vh;
    }
}
