// .wrapper {
// }

.fontColor {
  color: var(--text-secondary) !important;
}

.sideImage, .SideImage1, .SideImage2, .SideImage3 {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
  position: relative;
  .fillDots{
      position: absolute;
      z-index: 1;
      left: 0;
  }
  .greenDots{
      position: absolute;
      z-index: 1;
      top: 95%;
      left: 3%;
  }
  .line{
      position: absolute;
      z-index: 1;
      top: 80%;
      left: 55%;
  }
  .borderBg{
      width: 337px;
      height: 333px;
      position: absolute;
      // z-index: 1;
      border: 2px solid #03C7FD;
      left: 37%;
      top: 0%;
      border-radius: 50px;
  }

  img {
      position: relative;
      border-radius: 15% !important;
  }
}

@media (min-width: 1400px) {
  .sideImage {    
      .fillDots{
          left: 7%;
      }    
      .greenDots{          
          top: 93%;
          left: 8%;
      }
     
  }
}

//tab
@media (max-width: 991.98px){
  .sideImage {    
      .fillDots{
          left: 12%;
      }    
      .greenDots{          
          top: 90%;
          left: 12%;
      }
     
  }
}

@media (max-width: 575.98px){
  .sideImage {
     
      .fillDots{
        
          left: -5%;
      }
      .greenDots{
         
          top: 80%;
          left: 3%;
      }
      .line{
         
          top: 70%;
         
      }
      .borderBg{
          width: 280px;
          height: 300px;
          left: 15%;
         
      }
  
      img {
         width: 100%;
         padding-bottom: 5rem;
      }
  }
}


// .useCasesWrapper {
//   padding: 5px;
//   text-align: center;
//   color: #ffffff;
//   background-color: darkorange;
// }

// h1 {
//   font-size: 2.5em;
//   // margin-bottom: 20px;
//   // margin-left: 150px;

// }

// p {
//   font-size: 1.2em;
// }