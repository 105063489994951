.wrapper {
    height: 110vh;
    padding-top: 3rem;

    .subTitle {
        color: var(--text-secondary);
    }

    .subPara {
        width: 40rem;
    }

    .processLine {
        display: flex;
        justify-content: center;
        position: relative;

        .numberOne {
            position: absolute;
            font-size: 13rem;
            left: 7%;
            top: -20%;
            color: #94a2b63b;
        }

        .numberTwo {
            position: absolute;
            font-size: 13rem;
            color: #94a2b63b;
            left: 35%;
            top: 62%;
        }

        .numberThree {
            position: absolute;
            font-size: 13rem;
            color: #94a2b63b;
            left: 60%;
            top: -27%;
        }

        .numberFour {
            position: absolute;
            font-size: 13rem;
            color: #94a2b63b;
            left: 85%;
            top: -5%;
        }

        .one {
            position: absolute;
            left: 7%;
            top: 62%;
        }

        .two {
            position: absolute;
            left: 35%;
            top: 62%;
        }

        .three {
            position: absolute;
            left: 60%;
            top: 53%;
        }

        .four {
            position: absolute;
            left: 88%;
            top: -3%;
        }

        .paraOne {
            position: absolute;
            left: 7%;
            top: 90%;

            :nth-child(2) {
                width: 15rem;
            }
        }

        .paraTwo {
            position: absolute;
            left: 35%;
            top: 30%;
            

            :nth-child(2) {
                width: 15rem;
            }
        }

        .paraThree {
            position: absolute;
            left: 60%;
            top: 80%;

            :nth-child(2) {
                width: 15rem;
            }
        }

        .paraFour {
            position: absolute;
            left: 85%;
            top: -50%;

            :nth-child(2) {
                width: 12rem;
            }
        }


    }
}

@media (min-width: 1400px) {
    .wrapper {
        height: 85vh;

        .subPara {
            width: 20rem;
        }

        .processLine {
            .numberOne {
                left: 11%;
                top: -20%;
            }

            .numberTwo {
                left: 35%;
                top: 64%;
            }

            .numberThree {
                left: 57%;
                top: -27%;
            }

            .numberFour {
                left: 77%;
                top: -5%;
            }

            .one {
                left: 12%;
                top: 62%;
            }

            .two {
                left: 35%;
                top: 67%;
            }

            .three {
                left: 60%;
                top: 50%;
            }

            .four {
                left: 80%;
                top: -10%;
            }

            .paraOne {
                left: 12%;
                top: 90%;

                :nth-child(2) {
                    width: 10rem;
                }
            }

            .paraTwo {
                left: 35%;
                top: 15%;

                :nth-child(2) {
                    width: 10rem;
                }
            }

            .paraThree {
                left: 60%;
                top: 80%;

                :nth-child(2) {
                    width: 12.5rem;
                }
            }

            .paraFour {
                left: 80%;
                top: -55%;

                :nth-child(2) {
                    width: 10rem;
                }
            }


        }
    }
}

//tab
@media (max-width: 991.98px) {
    .wrapper {
        height: 70vh;
        overflow-x: hidden;
        .subPara {
            width: 20rem;
        }

        .processLine {

            .numberOne {
                left: -3%;
                top: -20%;
            }

            .numberTwo {
                left: 35%;
                top: 60%;
            }

            .numberThree {
                left: 60%;
                top: -35%;
            }

            .numberFour {
                left: 87%;
                top: 0%;
            }

            .processSvg {
                svg {
                    width: 100%;
                }
            }

            .one {
                left: -1%;
                top: 62%;
            }

            .two {
                left: 35%;
                top: 55%;
            }

            .three {
                left: 70%;
                top: 40%;
            }

            .four {
                left: 93%;
                top: 0%;
            }

            .paraOne {
                left: 0%;
                top: 90%;
            }

            .paraTwo {
                left: 35%;
                top: 5%;
            }

            .paraThree {
                left: 70%;
                top: 70%;
            }

            .paraFour {
                left: 81%;
                top: -45%;
            }


        }
    }
}

// mobile
@media (max-width: 575.98px) {
    .wrapper {
        width: 100vw;
        height: 160vh;
        .subPara {
            // width: 0rem;

            padding-bottom: 3rem;
        }

        .processLine {
            padding-top: 5rem;

            .processSvg {
                rotate: 110deg;
                
                svg {
                    border-radius: 30%;
                    width: 170%;
                }
            }

            .numberOne {
                font-size: 8rem;
                left: 20%;
                top: -15%;
            }

            .numberTwo {
                font-size: 8rem;
                left: 2%;
                top: 40%;
            }

            .numberThree {
                font-size: 8rem;
                left: 2%;
                top: 112%;
            }

            .numberFour {
                font-size: 8rem;
                left: 20%;
                top: 168%;
            }

            .one {
                left: 40%;
                top: 3%;
            }

            .two {
                left: 23%;
                top: 55%;
            }

            .three {
                left: 10%;
                top: 105%;
            }

            .four {
                left: 25%;
                top: 160%;
            }

            .paraOne {
                left: 57%;
                top: 21%;
            }

            .paraTwo {
                left: 47%;
                top: 60%;
            }

            .paraThree {
                left: 42%;
                top: 109%;
            }

            .paraFour {
                left: 50%;
                top: 165%;
            }


        }
    }
}