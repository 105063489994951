.wrapper{
    min-height: 70vh;
    background-color: #E2F5FF80;
     
   
}
.cusbtn {
    color: var(--white) !important;
    background: var(--text-secondary) !important;
    font-weight: 600 !important;
    border-radius: 20px !important;
    border: 1px solid var(--btn-border-color) !important;
    &:hover{
        background: var(--btn-hover-bg) !important;
    }
}

.cusbtn1 {
    color: var(--white) !important;
    background: var(--text-secondary) !important;
    font-weight: 600 !important;
    border-radius: 20px !important;
    margin-top: 80px;
    border: 1px solid var(--btn-border-color) !important;
    &:hover{
        background: var(--btn-hover-bg) !important;
    }
}


.cusbtnOne {
    color: var(--text-secondary) !important;
    font-weight: 600 !important;
    border-radius: 20px !important;
    border: 1px solid var(--btn-border-color) !important;
    &:hover{
        background: var(--white) !important;
    }
}

form{
    input:focus{
        border: 1px solid var(--btn-border-color) !important;
    }
}

//tab
@media (min-width: 768px) and (max-width: 991.98px){

}

.priceCard{
    width: 25rem;
}


/* AccordionStyles.css */

/* Hide both icons initially */
.accordion-icon .icon-default,
.accordion-icon .icon-open {
  font-size: 1.2rem;
  transition: transform 0.2s ease-in-out;
  display: none;
}

/* Show plus icon when collapsed */
.accordion-button.collapsed .accordion-icon .icon-default {
  display: inline;
}

/* Show minus icon and rotate when expanded */
.accordion-button:not(.collapsed) .accordion-icon .icon-open {
  display: inline;
  transform: rotate(180deg); /* Adjust angle if needed */
}
