.wrapper {

    .sideImage {
        display: flex;
        justify-content: center;
        padding-top: 3rem;
        position: relative;

        .fillDots {
            position: absolute;
            left: 33%;
            top: 75%;
        }

        .greenDots {
            position: absolute;
            top: 25%;
            left: 0%;
        }

        img {
            width: 80%;
            position: relative;

        }

        .borderBg {
            width: 200px;
            height: 300px;
            position: absolute;
            border: 2px solid #8976FD;
            left: 33%;
            top: 0%;
            border-radius: 50px;
        }
    }

}

.fontColor {
    color: var(--text-secondary) !important;
}


.CardIcon {
    // padding: 20px;
    width: 85px;
    height: 85px;
    border-radius: 15px;
    opacity: 45%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        position: static;
        top: 40%;
        left: 40%;
    }

}

.one {
    background-color: #FDBA09;
}

.two {
    background-color: #6DCBFF;

}

.three {
    background-color: #6DEDC3;

}

@media (min-width: 1400px) {
    .wrapper {
        .sideImage {
            .fillDots {
                left: 33%;
                top: 75%;
            }

            .greenDots {
                top: 10%;
                left: 5%;
            }

        }
    }
}

//tab
@media (max-width: 991.98px){
    .wrapper {
        .sideImage {
            padding-top: 5rem;
            .borderBg {
                width: 230px;
                height: 343px;
                top: 6%;
            }

        }
    }
}

// mobile
@media (max-width: 575.98px) {
    .wrapper {
        .sideImage {
            padding-top: 4rem;
            .borderBg {
                width: 180px;
                height: 243px;
                top: 6%;
            }
            .fillDots {
                left: 20%;
                top: 75%;
            }

        }
    }
}