.wrapper {
    
    .para{
        width: 80%;
    }
    .text{
        color: var(--text-primary);
    }
    .sideImage {
        display: flex;
        justify-content: center;
        padding-top: 3rem;
        position: relative;
        
        img{
            border-radius: 6px;
        }
        .fillDots {
            position: absolute;
            left: 85%;
            top: 3%;
            z-index: -1;
        }

        .greenDots {
            position: absolute;
            top: 25%;
            left: -1%;
            z-index: -1;
        }

        .line {
            position: absolute;
            top: 70%;
            left: 73%;
            z-index: -1;
            transform: rotate(210deg);
        }


        img {
            width: 100%;
        }
    }

}

.fontColor {
    color: var(--text-secondary) !important;
}




.CardIcon {
    width: 65px;
    height: 65px;
    border-radius: 15px;
    opacity: 45%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        position: static;
        top: 40%;
        left: 40%;
    }

}

.one {
    background-color: #FDBA09;
}

.two {
    background-color: #6DCBFF;
}

.three {
    background-color: #6DEDC3;
}

@media (min-width: 1400px) {
    .wrapper {
        max-width: 1400px !important;
        
        .sideImage {
            .fillDots {
                left: 90%;
                top: 0;
            }
    
            .greenDots {
                overflow: visible;
                top: 25%;
                left: -2%;
            }
    
            .line {
                top: 73%;
                left: 75%;
                transform: rotate(210deg);
            }

        }
    }
}

//tab
@media (max-width: 991.98px) {
    .wrapper {
        .para{
            width: 100%;
        }
        .sideImage {
            .line {
                top: 73%;
                left: 76%;
            }
        }
    }
}

@media (min-width: 1400px) {
    .wrapper{
        height: 110vh;
    }
}