@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

:root {
  --font-family: Inter, sans-serif, serif;
  --font-size: 1.3rem;
  --font-thin: 100;
  --font-light: 300;
  --font-regular: 400;
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-bold: 700;
  --font-extra-bold: 800;
  --font-black: 900;
  --border: #e4e8eb;
  --text-primary: #332c5c;
  --text-secondary: #f78900;
  --white: #fff;
  --btn-color: #fff;
  --btn-bg: #f78900;
  --btn-border-color: #f78900;
  --btn-hover-color: #fff;
  --btn-hover-bg: #e7840b;
  --btn-hover-border-color: #e7840b;
  --btn-focus-shadow-rgb: 49, 132, 253;
  --btn-active-color: #fff;
  --btn-active-bg: #e7840b;
  --btn-active-border-color: #e7840b;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #e7840b;
  --btn-disabled-border-color: #e7840b;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.navbar-toggler {
  background: none !important;
  border: none !important;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}

.shadow-1 {
  box-shadow:
    rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px !important;
  border: none !important;
}

.border-color{
  border: 1px solid var(--border) !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}